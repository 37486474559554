const apiBaseUrl = process.env.API_BASE_URL;

export async function getLoggedInUser() {
  return await fetch('/auth/session', {
    method: 'GET',
  }).then((response) => {
    if (!response.ok) return false
    return response.json()
  });
}

export async function loginUser(userData) {
  return await fetch('/auth/local', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  }).then((response) => {
    if (!response.ok) return false
    return response.json()
  });
}

export async function logoutUser() {
  return await fetch('/auth/signout', {
    method: 'GET',
  }).then((response) => {
    if (!response.ok) return false
    return response.json()
  });
}